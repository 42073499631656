* {
  box-sizing: border-box;
}

body, p {
  margin: 0;
  padding: 0;
  background: #FFF;
  color: #555;
  font-family: 'Monserrat', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 20px;
  text-rendering: optimizeLegibility;
}
